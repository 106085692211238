
h3{
    font-family: cursive, Arial, Helvetica, sans-serif;
}

#page-container {
    position: relative;
    min-height: 100vh;
  }

#content-wrap {
    padding-bottom: 70px;    /* Footer height */
  }

