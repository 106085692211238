#footer{
    background-color: lightgrey;
    position: absolute;
    bottom: 0;
    height: 70px;
    width: 100%;
    padding-top: 10px;
}

#footer img{
    height: 50px;
    margin: 0 10px;
    
}

#footer .container div {
    float: left;
    width: 50%;
    text-align: center;
}

#footer p{
    margin-top: 15px;
}

@media (max-width : 992px){
    
    #footer{
        padding-top: 5px;
    }

    #footer .container div{
        width: 100%;
    }

    #footer img{
        height: 40px;
        margin: 0 10px;
        
    }

    #footer p{
        margin-top: 0px;
        font-size: 12px;
        display: inline;
    }
}