#carousel img{
    height: 200px;
}

@media (max-width : 1200px){
    #carousel img{
        height: 180px;
    }
}

@media (max-width : 992px){
    #carousel img{
        height: 130px;
    }
}

@media (max-width : 768px){
    #carousel img{
        height: 80px;
    }
}